import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {CartActions} from '@wix/wixstores-client-core/dist/es/src/cart-actions/cartActions';
import {ProductActions} from '@wix/wixstores-client-core/dist/es/src/product-actions/ProductActions';
import {PubSubManager} from '@wix/wixstores-client-core/dist/es/src/pub-sub-manager/pubSubManager';
import {PageMap, AddToCartActionOption, STORAGE_PAGINATION_KEY} from '@wix/wixstores-client-core/dist/es/src/constants';
import {IProductWidgetDTO} from '../types/app-types';
import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types';
import {FedopsLogger} from '@wix/fedops-logger';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/es/src/types/site-map';

export class ProductService {
  private readonly cartActions: CartActions;
  private readonly productActions: ProductActions;

  constructor(
    private readonly siteStore: SiteStore,
    pubSubManager: PubSubManager,
    private readonly compId: IWidgetControllerConfig['compId'],
    private readonly externalId: string,
    private readonly fedopsLogger: FedopsLogger
  ) {
    this.cartActions = new CartActions(this.siteStore, pubSubManager, 'product-page', PageMap.CART);
    this.productActions = new ProductActions(this.siteStore);
  }

  private async trackAddToCartBI(product) {
    const eventData = {
      appName: 'productWidgetApp',
      hasOptions: false,
      productId: product.id,
      isNavigateCart: this.cartActions.shouldNavigateToCart(),
      navigationClick: this.cartActions.shouldNavigateToCart() ? 'cart' : 'mini-cart',
      productType: product.productType,
      origin: 'product-widget',
    };

    return this.siteStore.biLogger.clickOnAddToCartSf(eventData);
  }

  private storeNavigationHistory() {
    const pageId = this.siteStore.siteApis.currentPage.id;
    const history: IStoreFrontNavigationContext = {
      pageId,
      paginationMap: [],
    };
    this.siteStore.storage.local.setItem(STORAGE_PAGINATION_KEY, JSON.stringify(history));
  }

  public handleClick(product: IProductWidgetDTO, navigate: boolean = false): Promise<any> {
    const customTextFields = undefined;
    const selectionIds = undefined;
    const quantity = 1;

    if (navigate) {
      this.storeNavigationHistory();

      return this.siteStore.navigate(
        {
          sectionId: PageMap.PRODUCT,
          state: product.urlPart,
          queryParams: undefined,
        },
        true
      );
    } else if (!product.hasOptions) {
      // tslint:disable-next-line no-floating-promises
      this.trackAddToCartBI(product);
      this.fedopsLogger.interactionStarted('add-to-cart');
      return this.cartActions
        .addToCart(product.id, selectionIds, quantity, customTextFields, AddToCartActionOption.MINI_CART)
        .then(() => {
          this.fedopsLogger.interactionEnded('add-to-cart');
        });
    } else {
      return this.productActions.quickViewProduct(
        product.id,
        'productwidget',
        product.urlPart,
        this.compId,
        this.externalId
      );
    }
  }
}
